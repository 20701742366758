import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Spacer,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  User,
} from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
import axios from "../../axiosInstance";
import OwnBar from "./Charts/OwnBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

const ComingSoon = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const users = queryParams.get("users").split(",");
  const [storageUsers, setStorageUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [totalsData, setTotalsData] = useState();
  const [trophiesData, setTrophiesData] = useState();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [profilePics, setProfilePics] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of storageUsers) {
        const profilePic = localStorage.getItem(user + "_profilePic");
        if (profilePic) {
          profilePicsUpdates[user] = "data:image/jpeg;base64," + profilePic;
        } else {
          try {
            const response = await axios.get(`/getProfilePic?username=${user}`);
            if (response.data !== "") {
              profilePicsUpdates[user] =
                "data:image/jpeg;base64," + response.data;
              localStorage.setItem(user + "_profilePic", response.data);
            } else {
              profilePicsUpdates[user] = null;
            }
          } catch (error) {
            console.error("Failed to fetch profile pic for", user, ":", error);
          }
        }
      }
      setProfilePics(profilePicsUpdates);
    };

    if (storageUsers.length > 0) {
      fetchProfilePics();
    }
  }, [storageUsers]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setStorageUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    if (storageUsers.length === 0) {
      fetchUsers();
    }
  }, [storageUsers.length]);

  function addUserToSelected(newUser) {
    setSelectedUsers((prevSelectedUsers) => {
      // Check if the user is already selected
      if (prevSelectedUsers.includes(newUser)) {
        // Remove the user if they are already selected
        return prevSelectedUsers.filter((user) => user !== newUser);
      } else if (prevSelectedUsers.length < 2) {
        // Add the new user if there are less than 2 users selected
        return [...prevSelectedUsers, newUser];
      }
      // Return previous state if there are already 2 users and trying to add another one
      return prevSelectedUsers;
    });
  }

  function redirectToCompare() {
    if (selectedUsers.length === 2) {
      window.location.href = "/compare?users=" + selectedUsers;
    }
  }

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const response = await axios.get(
          `/stats/compare/totals?users=${users}`
        );
        setTotalsData(response.data);
      } catch (error) {
        console.error("Failed to fetch user totals", error);
      }
    };

    fetchTotals();
  }, []);

  useEffect(() => {
    const fetchTrophies = async () => {
      try {
        const response = await axios.get(
          `/stats/compare/trophies?users=${users}`
        );
        setTrophiesData(response.data);
      } catch (error) {
        console.error("Failed to fetch user totals", error);
      }
    };

    fetchTrophies();
  }, []);

  return (
    <div>
      <Button
        size="lg"
        color="primary"
        variant="solid"
        style={{
          position: "fixed",
          right: "15px",
          bottom: "20px",
          zIndex: 10,
          boxShadow:
            "0px 0px 10px hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)))",
        }}
        onClick={() => onOpen()}
      >
        Compare users
      </Button>
      <Navbar active="" logoSrc="../" />
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <h1 style={{ fontSize: "2rem", margin: "1rem 0 2rem 0" }}>Compare</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0 1.5rem",
          }}
        >
          {users.map((user, index) => (
            <React.Fragment key={user}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                  width: "100%",
                }}
              >
                <Avatar
                  style={{ width: "5rem", height: "5rem" }}
                  src={profilePics[user]}
                />
                <p style={{ fontSize: "1.3rem" }}>{user}</p>
              </div>
              {index === 0 && <h1 style={{ fontSize: "1.3rem" }}>vs</h1>}
            </React.Fragment>
          ))}
        </div>
        <Spacer y={5} />
        {totalsData && (
          <>
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Total</h2>
            <OwnBar data={totalsData} type="total" icon="chartSimple" />
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Mixed</h2>
            <OwnBar data={totalsData} type="mixed" icon="pieChart" />
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Pure</h2>
            <OwnBar data={totalsData} type="pure" icon="pieChart" />
          </>
        )}
        {trophiesData && (
          <>
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Earned</h2>
            <OwnBar data={trophiesData} type="earned" icon="trophy" />
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Weekly</h2>
            <OwnBar data={trophiesData} type="weekly" icon="trophy" />
            <h2 style={{ fontSize: "1.2rem", marginBottom: "" }}>Monthly</h2>
            <OwnBar data={trophiesData} type="monthly" icon="trophy" />
          </>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{
          width: "70%",
          backgroundColor: "#1e1e1e",
          maxHeight: "70%",
          color: "white",
        }}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Select users
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    padding: "0.5rem 0",
                  }}
                >
                  {storageUsers.map((user) => (
                    <div
                      style={{
                        width: "100%",
                        border: selectedUsers.includes(user)
                          ? "3px solid hsl(var(--nextui-primary)"
                          : "3px solid rgb(53 53 53)",
                        borderRadius: "0.5rem",
                        padding: "0.4rem 2rem 0.4rem 0.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={user}
                      onClick={() => addUserToSelected(user)}
                    >
                      <User
                        name={user}
                        description={user.description || ""}
                        style={{
                          color: "white",
                        }}
                        avatarProps={{
                          src: profilePics[user] || "", // Default image or loading state can be handled here
                        }}
                        isFocusable
                      />
                      {selectedUsers.includes(user) && (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="lg"
                          color="hsl(var(--nextui-primary)"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="justify-between">
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  variant="solid"
                  isDisabled={selectedUsers.length !== 2}
                  onPress={() => redirectToCompare()}
                >
                  Compare
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ComingSoon;
